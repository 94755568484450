import { styled } from '@/stitches.config'
import {Flex, Box} from '@/components/primitives'
import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  ReactNode,
} from 'react'
import { CSS } from '@stitches/react'

const StyledTextArea = styled('textarea', { 
  all: 'unset', 
  width: '100%',
  px: 16,
  py: 12,
  borderRadius: 8,
  fontFamily: '$body',
  fontSize: 16,
  color: '$gray12', 
  backgroundColor: '$gray3',
  $$focusColor: '$colors$primary8',
  '&::placeholder': { color: '$gray10' },
  '&:focus': { boxShadow: 'inset 0 0 0 2px $$focusColor' },
  '&:disabled': {
    backgroundColor: '$gray2',
    color: '$gray9',
  },

  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },

  '&[type=number]': {
    '-moz-appearance': 'textfield',
  },
})

const Input = forwardRef<
  ElementRef<typeof StyledTextArea>,
  ComponentPropsWithoutRef<typeof StyledTextArea> & {
    icon?: ReactNode
    containerCss?: CSS
  }
>(({ children, icon, containerCss, ...props }, forwardedRef) => (
  <Flex css={{ ...containerCss, position: 'relative' }}>
    {icon && (
      <Box style={{ position: 'absolute', top: 16, left: 16 }}>{icon}</Box>
    )}
    <StyledTextArea css={{ pl: icon ? 48 : 16 }} ref={forwardedRef} {...props}  autoComplete="off"/>
  </Flex>
))

export default Input
