
// context/index.tsx

'use client'

import React, { ReactNode } from 'react'
import { config, projectId } from '../config'

import { createWeb3Modal } from '@web3modal/wagmi/react'
import { mainnet } from 'viem/chains'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { State, WagmiProvider } from 'wagmi'

// Setup queryClient
const queryClient = new QueryClient()

if (!projectId) throw new Error('Project ID is not defined')

// Create modal
createWeb3Modal({
  wagmiConfig: config, 
  projectId,
  allWallets:'SHOW', 
  privacyPolicyUrl:'https://www.coinpulsepro.com/privacypolicy',
  termsConditionsUrl:'https://www.coinpulsepro.com/teamscondition',
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true, // Optional - false as default
  themeVariables:{
    '--w3m-z-index':100000000000 
  }
})

export default function Web3ModalProvider({
  children,
  initialState
}: {
  children: ReactNode
  initialState?: State
}) {
  return (
    <WagmiProvider config={config} initialState={initialState}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}
    