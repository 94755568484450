import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'
import supportedChains from '@/utils/chains'
import { cookieStorage, createStorage, createConfig, http } from 'wagmi'
import { Chain , mainnet, arbitrum, sepolia } from 'wagmi/chains' 
import type { CreateConnectorFn } from '@wagmi/core'
import { walletConnect, coinbaseWallet, injected } from "wagmi/connectors";
import { authConnector } from "@web3modal/wagmi";

// Get projectId from https://cloud.walletconnect.com
export const projectId = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID

if (!projectId) throw new Error('Project ID is not defined')

// Create a metadata object
const metadata = {
  name: 'Coinpulse Online',
  description: 'At Coin Pulse, we understand the growing prominence of cryptocurrencies and their impact on the investment landscape. ',
  url: 'https://web3modal.com', // origin must match your domain & subdomain
  icons: ['https://imagedelivery.net/_aTEfDRm7z3tKgu9JhfeKA/69c2e004-0e13-4c40-19ae-013f0bfd2f00/sm']
}

// Create wagmiConfig
//const chains = [mainnet, sepolia, arbitrum, polygon, avalanche, bsc, optimism, zkSync, base, zora, celo, aurora, opBNB, ancient8, arbitrumNova, linea, polygonZkEvm, scroll, blast, astarZkEVM] as const

// create the connectors
const connectors: CreateConnectorFn[] = []
connectors.push(walletConnect({ projectId, metadata, showQrModal: false ,isNewChainsStale:true}));
connectors.push(injected({ shimDisconnect: true }));
connectors.push(coinbaseWallet({
  appName: metadata.name,
  appLogoUrl: metadata.icons[0],
  preference:'all',
  version:'4'
}));

connectors.push(authConnector({ 
  options: { projectId },
  socials: ['google', 'x', 'github', 'discord', 'apple'], // this will create a non-custodial wallet (please check https://secure.walletconnect.com/dashboard for more info)
  showWallets: true,
  chains:(supportedChains.length === 0 ? [mainnet] : supportedChains) as [
    Chain,
    ...Chain[]
  ],
  email: true,
  walletFeatures: true,
}));

export const config = createConfig({
  
  chains:(supportedChains.length === 0 ? [mainnet] : supportedChains) as [
    Chain,
    ...Chain[]
  ], // Use the defined chains here
  transports: {
    [mainnet.id]: http(),
    [arbitrum.id]: http(),
  },
  connectors,
  ssr:true,
  storage: createStorage({
    storage: cookieStorage
  })
}); 